<template>
  <v-row :class="darkmode || 'white'">
    <v-col cols="12" class="mb-5 mt-2">
      <div class="d-flex align-center flex-row">
        <v-text-field
          v-model="table.search"
          :label="$t('app.search')"
          outlined
          dense
          hide-details
          clearable
          style="max-width: 250px"
          prepend-inner-icon="mdi-magnify"
          class="d-inline-block mr-2"
        />
        <v-tooltip v-if="checkAccessDataMultiple().length !== 0" bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="error white--text mr-3"
              @click="confirmDelete = true"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("e_test.delete_etest") }}</span>
        </v-tooltip>
        <v-spacer />
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              class="mt-5 mr-3"
              color="warning"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-information
            </v-icon>
          </template>
          <span>{{ $t("e_test.noaccess_information") }} </span>
        </v-tooltip>
        <v-checkbox
          v-model="table.my_etest"
          :label="$t('e_test.my_etest')"
          hide-details
          @change="refresh()"
        ></v-checkbox>
      </div>
    </v-col>
    <v-col cols="12">
      <v-data-table
        v-model="dataTableMultiple"
        :headers="tableHeaders"
        :items="dataTable.body.data"
        :page.sync="table.page"
        :items-per-page="table.limit"
        :loading="table.loading"
        :options.sync="options"
        show-select
        item-key="id"
        hide-default-footer
        disable-pagination
        class="elevation-0"
      >
        <template #item.data-table-select="{ item, isSelected, select }">
          <!-- PUBLISH || DRAFT -->
          <v-simple-checkbox
            v-if="
              (item.status === 'DRAFT' && g_user.person == item.created_by) ||
                g_user_type == 'ADMIN'
            "
            :value="isSelected"
            @input="select($event)"
          />
          <v-icon v-else color="grey lighten-3">mdi-square-outline</v-icon>
        </template>
        <template #item.type="{item}">
          {{ getTypeTest(item.type) }}
        </template>
        <template #item.hours="{item}">
          {{ getHours(item.start) }}
        </template>
        <template #item.start="{item}">
          {{ getDate(item.start) }}
        </template>
        <template #item.end="{item}">
          {{ getDate(item.start, true) }}
          - <br />
          {{ getDate(item.end, true) }}
        </template>
        <template #item.created_at="{item}">
          {{ getDate(item.created_at) }}
        </template>
        <template #item.remidi="{item}">
          <span
            v-if="g_user.person == item.created_by || g_user_type == 'ADMIN'"
          >
            <div>
              <v-tooltip v-if="statusRemidi(item) == 'NOTAVAILABLE'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon small>
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("e_test.test_time_is_not_over") }}</span>
              </v-tooltip>

              <v-tooltip v-if="statusRemidi(item) == 'DRAFT'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon small>
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("e_test.test_time_is_draft") }}</span>
              </v-tooltip>

              <v-btn
                v-if="statusRemidi(item) == 'AVAILABLE'"
                class="gradient-primary"
                dark
                icon
                small
                @click="$router.push(`/academic/remidi/${item.etest_class}`)"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <span class="ml-2" v-text="item.count_remedy" />
            </div>
          </span>
          <span v-else>
            <v-btn icon small>
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
          </span>
        </template>
        <template #item.duration="{item}">
          {{ item.duration + " " + $t("app.minutes") }}
        </template>
        <template #item.action="{item}">
          <v-menu
            v-if="
              g_user.person === item.created_by ||
                item.is_private === 0 ||
                g_user_type == 'ADMIN'
            "
            transition="slide-x-transition"
            bottom
            right
            rounded="lg"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :disabled="
                  table.loading || dataTableMultiple.length !== 0 ? true : false
                "
                fab
                small
                depressed
                icon
              >
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(menu, i) in detailMenu"
                :key="i"
                :disabled="disableMenu(item, menu.icon)"
                @click="getRoute(item, i)"
              >
                <v-list-item-title
                  ><v-icon
                    class="mr-2"
                    :color="disableMenu(item, menu.icon) ? 'grey' : 'primary'"
                    >{{
                      i === 1 && item.status === "PUBLISH"
                        ? "mdi-close-circle-outline"
                        : menu.icon
                    }}</v-icon
                  >
                  {{
                    i === 1 && item.status === "PUBLISH"
                      ? $t("app.cancel") + " " + menu.name
                      : menu.name
                  }}</v-list-item-title
                >
              </v-list-item>
            </v-list>
          </v-menu>

          <v-tooltip v-else bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                small
                class="gradient-primary"
                dark
                @click="
                  $router.push(`/academic/statistics/${item.etest_class}`)
                "
              >
                <v-icon small>mdi-trending-up</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("e_test.statistics") }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <Pagination
        :length="dataTable.body.last_page"
        :total="dataTable.body.total"
        :current-page="table.page"
        :limit="table.limit"
        :handler="paginateChange"
      />
      <CodeOfConduct
        :open="dialogCopy"
        :data="itemEtest"
        copy
        @toggle="toggleDialogCopy"
      />
      <AddEtest
        :open="openAddEvent"
        :info="dataTable"
        :id="idEtest"
        :isPublish="isPublish"
        view
        @close="toggleAddEtest"
      />
      <AddEtest
        :open="openContinuation"
        :info="itemEtest"
        :id="idEtest"
        continuation
        @close="toggleContinuation"
      />
      <ModalConfirm
        :close="() => (this.confirmDelete = false)"
        :loading="loadingDelete"
        :isOpen="confirmDelete"
        :save="deleteEtest"
      />
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment/moment";
moment.locale("id");
import store from "@/store";
import { deleteEtest } from "@/api/admin/academic/eTest";

export default {
  props: {
    table: Object,
    dataTable: Object
  },
  components: {
    Pagination: () => import("@/components/Pagination"),
    CodeOfConduct: () => import("../components/CodeOfConduct"),
    AddEtest: () => import("./dialog/AddEtest"),
    ModalConfirm: () => import("@/components/ModalConfirm")
  },
  computed: {
    darkmode() {
      return store.getters.g_darkmode;
    },
    g_user() {
      return store.getters.g_user;
    },
    g_user_type() {
      return store.getters.g_user.role_type;
    }
  },
  watch: {
    options: {
      handler(param) {
        this.sort(param);
      }
    }
  },
  data() {
    return {
      confirmDelete: false,
      loadingDelete: false,
      isPublish: false,
      itemDelete: {},
      dataTableMultiple: [],
      idEtest: 0,
      etestClass: 0,
      itemEtest: {},
      openAddEvent: false,
      openContinuation: false,
      dialogCopy: false,
      options: {},
      detailMenu: [
        { icon: "mdi-eye", name: "Detail" },
        { icon: "mdi-checkbox-marked-circle-outline", name: "Publish" },
        {
          icon: "mdi-format-list-bulleted",
          name: this.$i18n.t("e_test.question")
        },
        {
          icon: "mdi-information-outline",
          name: this.$i18n.t("e_test.code_of_conduct")
        },
        {
          icon: "mdi-clipboard-text",
          name: this.$i18n.t("e_test.view_results")
        },
        { icon: "mdi-trending-up", name: this.$i18n.t("e_test.statistics") },
        {
          icon: "mdi-account-edit",
          name: "Edit " + this.$i18n.t("e_test.chance")
        },
        {
          icon: "mdi-cached",
          name: this.$i18n.t("e_test.continuation")
        },
        {
          icon: "mdi-delete",
          name: this.$i18n.t("e_test.delete_etest")
        }
      ],
      tableHeaders: [
        { text: "#", value: "type" },
        {
          text: this.$i18n.t("e_test.table_header.detail_subjects.title"),
          value: "title"
        },
        {
          text: this.$i18n.t("e_test.table_header.detail_subjects.create_date"),
          value: "created_at"
        },
        {
          text: this.$i18n.t(
            "e_test.table_header.detail_subjects.release_date"
          ),
          value: "start"
        },
        {
          text: this.$i18n.t("e_test.table_header.detail_subjects.test_date"),
          value: "end"
        },
        {
          text: this.$i18n.t("e_test.table_header.detail_subjects.duration"),
          value: "duration",
          width: 110
        },
        {
          text: this.$i18n.t("e_test.table_header.detail_subjects.status"),
          value: "status"
        },
        {
          text: "Remidi",
          sortable: false,
          value: "remidi",
          width: 100
        },
        {
          text: this.$i18n.t("app.action"),
          align: "center",
          sortable: false,
          value: "action",
          width: 100
        }
      ]
    };
  },
  methods: {
    statusRemidi(item) {
      if (item.status == "PUBLISH") {
        if (item.end >= moment().valueOf()) {
          return "NOTAVAILABLE";
        } else return "AVAILABLE";
      } else {
        return "DRAFT";
      }
    },
    disableMenu(item, iconName) {
      let result = false;
      switch (iconName) {
        case "mdi-delete":
          result = item.status !== "DRAFT";
          break;
        case "mdi-clipboard-text":
          result = item.status === "DRAFT";
          break;
        case "mdi-cached":
          result =
            this.statusRemidi(item) == "NOTAVAILABLE" ||
            item.status === "DRAFT";
          break;
      }
      return result;
    },
    checkAccessDataMultiple() {
      const etestClasses = [];
      this.dataTableMultiple.map(item => {
        if (
          this.g_user.person == item.created_by ||
          this.g_user_type == "ADMIN"
        )
          etestClasses.push(item);
      });
      return etestClasses;
    },
    async deleteEtest() {
      this.loadingDelete = true;
      let etestClasses = [];
      if (this.dataTableMultiple.length === 0) {
        etestClasses = [this.itemDelete];
      } else {
        this.dataTableMultiple.map(item => {
          if (
            this.g_user.person == item.created_by ||
            this.g_user_type == "ADMIN"
          )
            etestClasses.push(item);
        });
      }
      new Promise(async () => {
        await Promise.all(
          etestClasses.map(
            async etestClass => await this.singleDeleteEtest(etestClass)
          )
        );
        this.loadingDelete = false;
        this.confirmDelete = false;
        this.dataTableMultiple = [];
        this.itemDelete = {};
        this.refresh();
      });
    },
    async singleDeleteEtest(itemEtest) {
      try {
        const response = await deleteEtest({
          etest_class: itemEtest.etest_class
        });
        if (response.data.code) {
          this.snackBar(true, this.$i18n.t("e_test.msg_success_delete_etest"));
        } else {
          this.snackBar(false, this.$i18n.t("e_test.msg_failed_delete_etest"));
        }
      } catch (error) {
        console.error("deleteEtest()\n", error);
      }
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isSuccess ? "success" : "error"
      });
    },
    toggleAddEtest() {
      if (this.openAddEvent) this.refresh();
      this.openAddEvent = !this.openAddEvent;
    },
    toggleContinuation() {
      if (this.openContinuation) this.refresh();
      this.openContinuation = !this.openContinuation;
    },
    toggleDialogCopy() {
      if (this.dialogCopy) {
        this.itemEtest = {};
        this.refresh();
      }
      this.dialogCopy = !this.dialogCopy;
    },
    getTypeTest(type) {
      let result = "";
      switch (type) {
        case 1:
          result = this.$i18n.t("e_test.name_test.daily_test");
          break;
        case 2:
          result = this.$i18n.t("e_test.name_test.homework");
          break;
        case 3:
          result = this.$i18n.t("e_test.name_test.midterm_exam");
          break;
        case 4:
          result = this.$i18n.t("e_test.name_test.semester_exam");
          break;
      }
      return result;
    },
    getRoute(item, index) {
      switch (index) {
        case 0:
          this.idEtest = item.id;
          this.isPublish = item.status === "PUBLISH";
          this.toggleAddEtest();
          break;
        case 1:
          this.changePublish({
            id: item.etest_class,
            status: item.status === "DRAFT" ? "PUBLISH" : "DRAFT"
          });
          break;
        case 2:
          this.$router.push(`/academic/etest-question/${item.etest_class}`);
          break;
        case 3:
          this.itemEtest = item;
          this.toggleDialogCopy();
          break;
        case 4:
          this.$router.push(`/academic/see-results/${item.etest_class}`);
          break;
        case 5:
          this.$router.push(`/academic/statistics/${item.etest_class}`);
          break;
        case 6:
          this.$router.push(`/academic/view-chance/${item.etest_class}`);
          break;
        case 7:
          this.idEtest = item.id;
          this.itemEtest = {
            etestClass: item.etest_class,
            startDate: moment
              .unix(moment(item.start).unix())
              .format("YYYY-MM-DD"),
            endDate: null,
            startHours: moment.unix(moment(item.start).unix()).format("HH:mm"),
            endHours: null,
            ...this.dataTable
          };
          this.toggleContinuation();
          break;
        case 8:
          this.itemDelete = item;
          this.confirmDelete = true;
          break;
        default:
          this.$router.push("#");
          break;
      }
    },
    changePublish(item) {
      this.$emit("changeStatus", item);
    },
    paginateChange(page, limit) {
      this.$emit("onChangePaginate", { page: page, limit: limit });
    },
    sort(param) {
      if (param.sortBy.length != 0) {
        const order = param.sortDesc[0] ? "DESC" : "ASC";
        const sort = [param.sortBy[0]];
        this.$emit("sort", { sort: sort, order: order });
      }
    },
    refresh() {
      this.$emit("reload");
    },
    getHours: date => moment.unix(moment(date).unix()).format("HH:mm"),
    getDate: (date, hours) =>
      hours
        ? moment.unix(moment(date).unix()).format("DD MMM YYYY, HH:mm")
        : moment.unix(moment(date).unix()).format("DD MMM YYYY")
  }
};
</script>
